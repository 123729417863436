const { buildNamespacesMap } = require('@wix/wix-code-viewer-utils');
const { createClient } = require('@wix/sdk/client');

const createGlobals = ({
  active$wBiFactory,
  $w,
  wixSdk,
  userConsole,
  getAppDefIdFromPackageName,
}) => {
  const wrapped$w = active$wBiFactory.wrapFunctionReturnValueWithBi($w);
  wrapped$w.at = active$wBiFactory.wrapFunctionCallWithBi($w.at, $w);

  const wrappedWixSdk = buildNamespacesMap(
    wixSdk,
    self.fetch.bind(self),
    active$wBiFactory.wrapObjectPropertiesWithBi,
  );

  return {
    $w: wrapped$w,
    $ns: wrappedWixSdk,
    console: userConsole,
    $wixContext: {
      initWixModules: (wixModules) => {
        const client = createClient({
          auth: {
            getAuthHeaders() {
              return {
                headers: {
                  Authorization:
                    wixSdk.elementorySupport.getRequestOptions().headers
                      .Authorization,
                },
              };
            },
          },
        });

        return client.use(wixModules);
      },
    },
    elementorySupport: wixSdk.elementorySupport,
    getAppDefIdFromPackageName, // corresponding change in the bundling side can be found here: https://github.com/wix-private/cloud-runtime/pull/4025
  };
};

module.exports = {
  createGlobals,
};
